<template>
  <v-card raised elevation="6" class="mt-1">
    <v-card-title>
      <h4
        style="
          font-size: 15px;
          text-transform: uppercase;
          font-weight: 100;
          text-decoration: underline;
        "
      >
        {{ $t("clients.howMeet") }}
      </h4>
      <v-spacer></v-spacer>
      <v-btn @click="graphic = !graphic" :outlined="graphic" height="30px"
        >Tabla</v-btn
      >
    </v-card-title>
    <v-card-text v-if="graphic"> <DoughnutHowMeet /> </v-card-text>
    <v-data-table
      v-else
      :headers="headersHowMeet"
      :items="list"
      :items-per-page="5"
      id="paymentType-table"
    >
      <template v-slot:item.how_meet="{ item }">
        {{ item.how_meet }}
      </template>
      <template v-slot:item.total="{ item }">
        {{ item.total }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "ClientsHowMeet",
  components: {
    DoughnutHowMeet: () =>
      import("@/components/stats/clients/graphics/ClientsHowMeetGraphic"),
  },
  ...mapState("stats", ["lists"]),

  computed: {
    //METODO / VARIABLE
    list() {
      return this.$store.state.stats.lists.howMeet;
    },
  },
  data() {
    return {
      graphic: true,
      headersHowMeet: [
        {
          text: this.$t("clients.howMeet"),
          align: "center",
          sortable: false,
          value: "how_meet",
        },
        {
          text: this.$t("total"),
          align: "center",
          sortable: false,
          value: "total",
        },
      ],
    };
  },
};
</script>
